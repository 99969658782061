import marked from "marked";
import hljs from "highlight.js";
// import 'highlight.js/styles/atelier-cave-light.css';
import 'highlight.js/styles/atelier-plateau-light.css';
export default {
  props: {
    previewCode: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: ""
    };
  },
  mounted: function mounted() {
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function highlight(code) {
        return hljs.highlightAuto(code).value;
      },
      pedantic: false,
      gfm: true,
      tables: true,
      breaks: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      xhtml: false
    });
    for (var key in this.previewCode) {
      if (this.activeName == "") {
        this.activeName = key;
      }
      document.getElementById(key).innerHTML = marked(this.previewCode[key]);
    }
  }
};