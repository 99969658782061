var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "previewCode"
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.previewCode, function (item, key) {
    return _c("el-tab-pane", {
      key: key,
      attrs: {
        label: key,
        name: key
      }
    }, [_c("div", {
      staticStyle: {
        background: "#fff",
        padding: "0 20px"
      },
      attrs: {
        id: key
      }
    })]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };